import React from 'react';
import styled from 'styled-components';

import { Status, TLog } from '../../types';

import { RED, YELLOW, GREEN, BLUE, PURPLE, GRAY } from '../../constants';
import { JsonView, collapseAllNested, darkStyles } from 'react-json-view-lite';
import 'react-json-view-lite/dist/index.css';

// =============================================================================
// Styled Components
// =============================================================================

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 1.5;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const StyledSpan = styled.span<{ status: Status }>`
  color: ${(props) => {
    switch (props.status) {
      case 'success':
        return GREEN;
      case 'warning':
        return YELLOW;
      case 'error':
        return RED;
      case 'info':
        return BLUE;
      case 'debug':
        return GRAY;
    }
  }};
  margin-right: 5px;
  align-self: flex-start;
  min-width: 75px;
`;

const Method = styled.p`
  color: ${PURPLE};
  margin-right: 10px;
`;

const Message = styled.p`
  overflow-wrap: break-word;
`;

function isJson(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

// =============================================================================
// Main Component
// =============================================================================

const Log = React.memo((props: TLog) => (
  <Column>
    <Row>
      <StyledSpan status={props.status}>
        {'>'} {props.status}
      </StyledSpan>
      {props.providerType && <Method>{props.providerType}</Method>}
      {props.method && <Method>[{props.method}]</Method>}
      {isJson(props.message) ? (
        <JsonView
          data={JSON.parse(props.message)}
          shouldExpandNode={collapseAllNested}
          style={darkStyles}
        />
      ) : (
        <Message>{props.message}</Message>
      )}
      {props.messageTwo && <Message>{props.messageTwo}</Message>}
    </Row>
    {/* <div>
      {isJson(props.message) ? (
        <JsonView
          data={JSON.parse(props.message)}
          shouldExpandNode={collapseAllNested}
          style={darkStyles}
        />
      ) : (
        <Message>{props.message}</Message>
      )}
    </div> */}
    {/* {props.messageTwo && <Message>{props.messageTwo}</Message>} */}
  </Column>
));

export default Log;
