export const BALANCE_SAMPLES = [
  {
    label: '1. Get Balance',
    value: 'get-balance'
  }
];

const GET_BALANCE_CODE = `
(async () => {
  const accounts = await ethereum.request({ method: 'eth_requestAccounts' })
  const address = accounts[0]
  const balance = await rango.balance({ address, blockchain: "POLYGON" })
  console.log(balance)
})()
`;

export const BALANCE_SAMPLES_CODES = {
  'get-balance': GET_BALANCE_CODE.trimStart()
};
