/**
 * @DEV: If the sandbox is throwing dependency errors, chances are you need to clear your browser history.
 * This will trigger a re-install of the dependencies in the sandbox – which should fix things right up.
 * Alternatively, you can fork this sandbox to refresh the dependencies manually.
 */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { PublicKey } from '@solana/web3.js';
import vm from 'vm-browserify';
import { detectPhantomMultiChainProvider } from './utils';

import { PhantomInjectedProvider, TLog } from './types';

import { Logs, NoProvider, Sidebar } from './components';
import { connect, silentlyConnect } from './utils/connect';
import { setupEvents } from './utils/setupEvents';
import { useEthereumSelectedAddress } from './utils/getEthereumSelectedAddress';
import { RangoClient, SwapResponse as RangoSwap } from 'rango-sdk-basic';
import {
  RANGO_API_KEY,
  SOLANA,
  SOLANA_SOL,
  SOLANA_USDC,
  SOL_DECIMALS,
  signAndSendTransaction,
  swapToString
} from './rango';
import BigNumber from 'bignumber.js';
import { MenuSampleCodes, MenuSampleOptions } from './components/Sidebar/data';
import { Editor } from './components/Editor';
import { CommandBar } from './components/CommandBar';
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useNavigate,
  useParams,
  useSearchParams
} from 'react-router-dom';

import './App.css';
import { base64ToBytes } from './components/CommandBar/helpers';
import { ethers } from 'ethers';

// =============================================================================
// Styled Components
// =============================================================================

const StyledApp = styled.div`
  display: flex;
  flex-direction: row;
  height: 100vh;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const EditorContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

// =============================================================================
// Typedefs
// =============================================================================

export type ConnectedAccounts = {
  solana: PublicKey | null;
  ethereum: string | null;
};

export type ConnectedMethods =
  | {
      chain: string;
      name: string;
      onClick: (props?: any) => Promise<string>;
    }
  | {
      chain: string;
      name: string;
      onClick: (chainId?: any) => Promise<void | boolean>;
    };

interface Props {
  connectedAccounts: ConnectedAccounts;
  connectedMethods: ConnectedMethods[];
  handleConnect: () => Promise<void>;
  handleDisconnect: () => Promise<void>;
  logs: TLog[];
  clearLogs: () => void;
  code: string;
  setCode: (value: string) => void;
  run?: () => Promise<void>;
}

// =============================================================================
// Hooks
// =============================================================================
/**
 * @DEVELOPERS
 * The fun stuff!
 */
const useProps = (provider: PhantomInjectedProvider | null, rango: RangoClient): Props => {
  /** Logs to display in the Sandbox console */
  const [logs, setLogs] = useState<TLog[]>([]);
  const [rangoSwap, setRangoSwap] = useState<RangoSwap | null>(null);
  const [code, setCode] = useState<string>("console.log('Hello Rango!');");

  const createLog = useCallback(
    (log: TLog) => {
      return setLogs((logs) => [...logs, log]);
    },
    [setLogs]
  );

  const clearLogs = useCallback(() => {
    setLogs([]);
  }, [setLogs]);

  const handleRunCode = useCallback(async () => {
    const trango = new RangoClient('a5e0f595-a3a7-4c9b-9651-532fa4b0cfbf');
    let contextObj = {
      rango,
      trango,
      exports: {},
      module: {},
      ethereum: (window as any).phantom.ethereum,
      solana: (window as any).phantom.solana,
      signer: new ethers.providers.Web3Provider((window as any).phantom.ethereum).getSigner(),
      signAndSendSolanaTransaction: signAndSendTransaction,
      sleep: (ms: number) => new Promise((r) => setTimeout(r, ms)),
      console: {
        log: (args) => {
          createLog({
            status: 'debug',
            message: typeof args === 'string' ? args : JSON.stringify(args, undefined, 2)
          });
        },
        info: (args) => {
          createLog({
            status: 'info',
            message: typeof args === 'string' ? args : JSON.stringify(args, undefined, 2)
          });
        },
        warn: (args) => {
          createLog({
            status: 'warning',
            message: typeof args === 'string' ? args : JSON.stringify(args, undefined, 2)
          });
        },
        error: (args) => {
          createLog({
            status: 'error',
            message: typeof args === 'string' ? args : JSON.stringify(args, undefined, 2)
          });
        },
        success: (args) => {
          createLog({
            status: 'success',
            message: typeof args === 'string' ? args : JSON.stringify(args, undefined, 2)
          });
        }
      }
    };
    let res = undefined;
    try {
      const x = vm.createContext(contextObj);
      res = await vm.runInNewContext(code, x);
      console.log({ res });
    } catch (error) {
      console.log({ error });
      createLog({
        status: 'error',
        message: error.message
      });
    }
    return res;
  }, [code, createLog, rango, window]);

  const [ethereumSelectedAddress, setEthereumSelectedAddress] = useEthereumSelectedAddress(
    provider?.ethereum
  );

  /** Side effects to run once providers are detected */
  useEffect(() => {
    if (!provider) return;
    const { solana, ethereum } = provider;

    // attempt to eagerly connect on initial startup
    silentlyConnect({ solana, ethereum }, createLog);
    setupEvents({ solana, ethereum }, createLog, setEthereumSelectedAddress);

    return () => {
      solana.disconnect();
    };
  }, [provider, createLog, setEthereumSelectedAddress]);

  /** Connect to both Solana and Ethereum Providers */
  const handleConnect = useCallback(async () => {
    if (!provider) return;
    const { solana, ethereum } = provider;

    await connect({ solana, ethereum }, createLog);

    // Immediately switch to Ethereum Goerli for Sandbox purposes
    // await ensureEthereumChain(ethereum, SupportedEVMChainIds.EthereumGoerli, createLog);
  }, [provider, createLog]);

  /**
   * Disconnect from Solana
   * At this time, there is no way to programmatically disconnect from Ethereum
   * MULTI-CHAIN PROVIDER TIP: You can only disconnect on the solana provider. But after when disconnecting your should use the
   * multi-chain connect method to reconnect.
   */
  const handleDisconnect = useCallback(async () => {
    if (!provider) return;
    const { solana } = provider;
    try {
      await solana.disconnect();
    } catch (error) {
      createLog({
        providerType: 'solana',
        status: 'error',
        method: 'disconnect',
        message: error.message
      });
    }
  }, [provider, createLog]);

  const connectedMethods = useMemo(() => {
    return [
      {
        chain: 'solana',
        name: 'Disconnect',
        onClick: handleDisconnect
      }
    ];
  }, [handleDisconnect]);

  return {
    connectedAccounts: {
      solana: provider?.solana?.publicKey,
      ethereum: ethereumSelectedAddress
    },
    connectedMethods,
    handleConnect,
    handleDisconnect,
    logs,
    clearLogs,
    code,
    setCode,
    run: handleRunCode
  };
};

// =============================================================================
// Stateless Component
// =============================================================================

const StatelessApp = React.memo((props: Props) => {
  const {
    connectedAccounts,
    handleConnect,
    handleDisconnect,
    logs,
    clearLogs,
    code,
    setCode,
    run
  } = props;
  const { method, sample } = useParams();
  let [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const sharedCode = searchParams.get('code');

  const onCodeChange = React.useCallback(
    (val: string) => {
      setCode(val);
    },
    [setCode]
  );

  useEffect(() => {
    const sampleCode =
      MenuSampleCodes[method]?.[sample] || Object.values(MenuSampleCodes[method] || {})?.[0] || '';
    setCode(sampleCode);
    clearLogs();
  }, [method, sample, setCode, clearLogs]);

  useEffect(() => {
    if (!sharedCode) return;
    const c = new TextDecoder().decode(base64ToBytes(decodeURIComponent(sharedCode)));
    setCode(c);
  }, [sharedCode, setCode]);

  return (
    <StyledApp>
      <Sidebar
        connectedAccounts={connectedAccounts}
        connect={handleConnect}
        disconnect={handleDisconnect}
      />
      <EditorContainer>
        <Editor code={code} onChange={onCodeChange} />
        <CommandBar
          code={code}
          sampleOptions={MenuSampleOptions?.[method] || []}
          onChangeSample={(value) => {
            navigate(`/${method}/${value}/`);
            clearLogs();
          }}
          onClear={clearLogs}
          onRun={run}
        />
        <Logs connectedAccounts={connectedAccounts} logs={logs} />
      </EditorContainer>
    </StyledApp>
  );
});

// =============================================================================
// Main Component
// =============================================================================

const App = () => {
  const [provider, setProvider] = useState<PhantomInjectedProvider | null>(null);
  const rango = new RangoClient(RANGO_API_KEY);
  const props = useProps(provider, rango);

  useEffect(() => {
    const getPhantomMultiChainProvider = async () => {
      const phantomMultiChainProvider = await detectPhantomMultiChainProvider();
      setProvider(phantomMultiChainProvider);
    };
    getPhantomMultiChainProvider();
  }, []);

  if (!provider) {
    return <NoProvider />;
  }
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navigate to="/quote/bsc-dex/" replace />} />
        <Route path="/share" element={<StatelessApp {...props} />} />
        <Route path="/:method/:sample/" element={<StatelessApp {...props} />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
