import styled from 'styled-components';

// =============================================================================
// Styled Components
// =============================================================================

const StyledMain = styled.main`
  padding: 20px;
  height: 100vh;
  background-color: #070917;
  color: white;
`;

// =============================================================================
// Main Component
// =============================================================================

// TODO: @PHANTOM-TEAM: Let's improve this UI
const NoProvider = () => {
  return (
    <StyledMain>
      <h2>Please install Phantom wallet and try again</h2>
    </StyledMain>
  );
};

export default NoProvider;
