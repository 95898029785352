import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

import { TLog } from '../../types';

import { BLACK, GRAY } from '../../constants';

import Log from './Log';
import { ConnectedAccounts } from '../../App';

// =============================================================================
// Styled Components
// =============================================================================

const StyledSection = styled.section`
  position: relative;
  flex: 2;
  padding: 20px;
  background-color: ${BLACK};
  overflow: auto;
  font-family: monospace;
  align-self: flex-end;
  height: 100%;
  min-width: 100%;
  width: 0;
`;

const PlaceholderMessage = styled.p`
  color: ${GRAY};
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  span {
    margin-right: 10px;
  }
`;

// =============================================================================
// Typedefs
// =============================================================================

interface Props {
  connectedAccounts: ConnectedAccounts;
  logs: TLog[];
}

// =============================================================================
// Main Component
// =============================================================================

const Logs = React.memo((props: Props) => {
  const { connectedAccounts, logs } = props;
  const { solana } = connectedAccounts;
  const messagesEndRef = useRef(null);

  useEffect(() => {
    messagesEndRef.current.scrollTo(0, messagesEndRef.current?.scrollHeight);
  }, [logs?.length]);

  return (
    <StyledSection ref={messagesEndRef}>
      {logs.length > 0 ? (
        logs.map((log, i) => <Log key={`${log.status}-${log.method}-${i}`} {...log} />)
      ) : (
        <Row>
          <span>{'>'}</span>
          <PlaceholderMessage>
            {solana ? (
              // connected
              <>
                Click a button and watch magic happen...{' '}
                <span role="img" aria-label="Sparkles Emoji">
                  ✨
                </span>
              </>
            ) : (
              // not connected
              <>
                Welcome to the rango-sdk playground. Connect to your Phantom wallet and play around
                ...
              </>
            )}
          </PlaceholderMessage>
        </Row>
      )}
    </StyledSection>
  );
});

export default Logs;
