import React from 'react';
import styled from 'styled-components';

import { PURPLE, WHITE } from '../../constants';
import { hexToRGB } from '../../utils';
import { ConnectedAccounts } from '../../App';
import { SupportedChainIcons } from '../../types';
import { MenuItems, MenuSampleOptions } from './data';
import { NavLink, useParams } from 'react-router-dom';

import { Button, Flowbite, Sidebar } from 'flowbite-react';

// =============================================================================
// Styled Components
// =============================================================================

const StyledSidebar = styled(Sidebar)`
  position: relative;
  justify-content: space-beteen;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 320px;
  overflow: auto;

  & ul {
    padding-top: 15px;
    padding-bottom: 15px;
    & li {
      padding-top: 8x;
      padding-bottom: 8px;
    }
  }

  & > div:first-child {
    // background: #070917;
    padding: 20px 10px;
    color: white;
    width: 100%;
    border-radius: 0;
    border-right: 1px solid black;
  }

  @media (max-width: 768px) {
    width: 100%;
    min-width: inherit;
    height: auto;
    overflow: inherit;
    border: 0;
    > div {
      border-right: 0px;
      border-bottom: 2px solid black;
    }
  }
`;

const Pre = styled.pre`
  margin-bottom: 5px;
  margin-right: auto;
`;

const AccountRow = styled.div`
  display: flex;
  margin-bottom: 8px;

  :last-of-type {
    margin-bottom: 0;
  }
`;

const Badge = styled.div`
  margin: 0;
  padding: 7px;
  width: 100%;
  color: ${PURPLE};
  background-color: ${hexToRGB(PURPLE, 0.2)};
  font-size: 14px;
  border-radius: 0 6px 6px 0;
  @media (max-width: 400px) {
    width: 280px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  @media (max-width: 320px) {
    width: 220px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  ::selection {
    color: ${WHITE};
    background-color: ${hexToRGB(PURPLE, 0.5)};
  }

  ::-moz-selection {
    color: ${WHITE};
    background-color: ${hexToRGB(PURPLE, 0.5)};
  }
`;

const ChainIcon = styled.img`
  height: ${(props) => props.height};
  width: ${(props) => props.height};
  border-radius: 6px 0 0 6px;
`;
// =============================================================================
// Typedefs
// =============================================================================

interface Props {
  connectedAccounts: ConnectedAccounts;
  connect: () => Promise<void>;
  disconnect: () => Promise<void>;
}

// =============================================================================
// Main Component
// =============================================================================
const MySidebar = React.memo((props: Props) => {
  const { connectedAccounts, connect, disconnect } = props;
  const { method } = useParams();

  return (
    <>
      <Flowbite>
        <StyledSidebar>
          <Sidebar.Logo
            href="/"
            img="https://raw.githubusercontent.com/rango-exchange/assets/main/branding/icons/icon-white.png"
            imgAlt="Flowbite logo">
            SDK Playground
          </Sidebar.Logo>
          <Sidebar.Items>
            {connectedAccounts?.solana ? (
              <Sidebar.ItemGroup>
                <Pre className="text-xs inline-block mt-5">Connected as</Pre>
                <AccountRow>
                  <ChainIcon src={SupportedChainIcons.Ethereum} height="36px" />
                  <Badge>{connectedAccounts?.ethereum?.substring(0, 25)}...</Badge>
                </AccountRow>
                <AccountRow>
                  <ChainIcon src={SupportedChainIcons.Solana} height="36px" />
                  <Badge>{connectedAccounts?.solana?.toBase58().substring(0, 25)}...</Badge>
                </AccountRow>
                <Button onClick={disconnect} className="text-sm w-full" size="sm">
                  Disconnect
                </Button>
              </Sidebar.ItemGroup>
            ) : (
              <Sidebar.ItemGroup>
                <Pre className="text-xs inline-block mt-5 w-full">
                  Connect your wallet and start the journey
                </Pre>
                <Button onClick={connect} className="text-sm w-full">
                  Connect to Multi-Chain Phantom <br /> Account and Try Again
                </Button>
              </Sidebar.ItemGroup>
            )}
            <Sidebar.ItemGroup>
              <Sidebar.Collapse label="Basic SDK" open>
                {MenuItems.map((item, index) => (
                  <NavLink
                    key={index}
                    to={`/${item.value}/${MenuSampleOptions[item.value]?.[0]?.value || 'default'}`}>
                    <Sidebar.Item icon={item.icon} key={index} active={method === item.value}>
                      <span className="text-sm">{item.label}</span>
                    </Sidebar.Item>
                  </NavLink>
                ))}
              </Sidebar.Collapse>
            </Sidebar.ItemGroup>
          </Sidebar.Items>
        </StyledSidebar>
      </Flowbite>
    </>
  );
});

export default MySidebar;
