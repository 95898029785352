export const QUOTE_SAMPLES = [
  {
    label: '1. EVM: BSC Sample DEX',
    value: 'bsc-dex'
  },
  {
    label: '2. EVM: BSC to Polygon Bridge',
    value: 'bsc-to-polygon'
  }
];

const BSC_DEX_CODE = `
(async () => {
  const tokens = (await rango.meta()).tokens
  const usdt_address = "0x55d398326f99059ff775485246999027b3197955"
  const bsc_bnb = tokens.find(t => t.blockchain == "BSC" && t.address == null)
  const bsc_usdt = tokens.find(t => t.blockchain == "BSC" && t.address == usdt_address)
  const amount = "100000000000000000"

  const quote = await rango.quote({ from: bsc_bnb, to: bsc_usdt, amount })
  console.log(quote)  
})()
`;

const BSC_BRIDGE_CODE = `
(async () => {
  const tokens = (await rango.meta()).tokens
  const bsc_bnb = tokens.find(t => t.blockchain == "BSC" && t.address == null)
  const polygon_matic = tokens.find(t => t.blockchain == "POLYGON" && t.address == null)
  const amount = "100000000000000000"

  const quote = await rango.quote({ from: bsc_bnb, to: polygon_matic, amount })
  console.log(quote)  
})()
`;

export const QUOTE_SAMPLES_CODES = {
  'bsc-dex': BSC_DEX_CODE.trimStart(),
  'bsc-to-polygon': BSC_BRIDGE_CODE.trimStart()
};
