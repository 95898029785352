import CodeMirror from '@uiw/react-codemirror';
import { javascript } from '@codemirror/lang-javascript';
import styled from 'styled-components';
import { vscodeDark } from '@uiw/codemirror-theme-vscode';

const Container = styled.div`
  color: initial;
  min-width: 100%;
  width: 0;
  font-size: 13px;
`;

type Props = {
  code: string;
  onChange: (value: string) => void;
};

export function Editor(props: Props) {
  const { code, onChange } = props;
  return (
    <Container>
      <CodeMirror
        value={code}
        theme={vscodeDark}
        height="350px"
        extensions={[javascript({ jsx: true, typescript: true })]}
        onChange={onChange}
      />
    </Container>
  );
}
