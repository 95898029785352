import styled from 'styled-components';
import Select from 'react-select';
import { useEffect, useState } from 'react';
import { FaRegTrashAlt, FaPlay, FaShareAlt, FaCopy } from 'react-icons/fa';
import { Button, Modal } from 'flowbite-react';
import { MdDone } from 'react-icons/md';
import { FaLink } from 'react-icons/fa';
import { bytesToBase64 } from './helpers';
import { useParams } from 'react-router-dom';

const Container = styled.div`
  // background: #333333;
  display: flex;
  font-weight: bold;
  color: initial;
  & button {
    box-shadow: none !important;
  }
`;

const SelectContainer = styled.div`
  font-size: 12px;
  width: -webkit-fill-available;
`;

const StyledButtonGroup = styled(Button.Group)`
  & button {
    border-radius: 0;
    width: 150px;
  }
`;

type Props = {
  code: string;
  sampleOptions: { label: string; value: string }[];
  onChangeSample: (value: string) => void;
  onRun: () => Promise<void>;
  onClear: () => void;
};

export function CommandBar(props: Props) {
  const { sampleOptions, onChangeSample, code, onRun, onClear } = props;
  const [selectedSample, setSelectedSample] = useState<{ label: string; value: string }>(null);
  const [isRunning, setRunning] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState(false);
  const [copied, setCopied] = useState(false);
  const { sample } = useParams();

  const encoded = encodeURIComponent(bytesToBase64(new TextEncoder().encode(code)));
  const sharableCode = `${window.location.origin}/share?code=${encoded}`;

  useEffect(() => {
    if (sample) {
      setSelectedSample(sampleOptions?.find((item) => item.value === sample) ?? null);
    }
  }, [sample, setSelectedSample]);

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 2_000);
    }
  }, [copied, setCopied]);

  return (
    <Container>
      <SelectContainer>
        <Select
          placeholder="Search in sample codes ..."
          options={sampleOptions}
          className="h-full"
          styles={{
            control: (base) => ({
              ...base,
              height: '100%'
            })
          }}
          onChange={(value) => {
            setSelectedSample(value);
            onChangeSample(value.value);
          }}
          value={selectedSample}
        />
      </SelectContainer>
      <StyledButtonGroup>
        <Button
          className="w-64"
          onClick={() => {
            setOpenModal(true);
          }}
          color="indigo">
          <FaShareAlt className="mr-3" />
          Share
        </Button>
        <Button className="w-64" onClick={onClear}>
          <FaRegTrashAlt className="mr-3" />
          Clear Logs
        </Button>
        <Button
          className="w-64"
          onClick={async () => {
            setRunning(true);
            onClear();
            await onRun?.();
            setRunning(false);
          }}
          color="success"
          disabled={isRunning}>
          <FaPlay className="mr-3" />
          Run Code
        </Button>
      </StyledButtonGroup>
      <Modal show={openModal} onClose={() => setOpenModal(false)} size="md" dismissible>
        <Modal.Header>Share Code</Modal.Header>
        <Modal.Body>
          <div className="flex gap-2 w-full">
            <Button
              onClick={() => {
                setCopied(true);
                navigator.clipboard.writeText(sharableCode);
              }}
              className="text-base leading-relaxed w-full">
              {copied ? <MdDone size="20" /> : <FaCopy />}
              &nbsp;
              {copied ? 'Copied' : 'Copy'}
            </Button>
            <Button
              target="_blank"
              href={sharableCode}
              className="text-base leading-relaxed w-full">
              <FaLink />
              &nbsp; Link
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </Container>
  );
}
