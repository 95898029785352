import {
  Commitment,
  Connection,
  PublicKey,
  Transaction,
  TransactionInstruction,
  VersionedTransaction
} from '@solana/web3.js';
import { PhantomSolanaProvider } from '../types';
import { FREE_SOLANA_RPC } from './constants';
import { SolanaTransaction } from 'rango-sdk-basic';
import { Buffer } from 'buffer';

function getSolanaConnection(): Connection {
  const connectionConfig = {
    commitment: 'confirmed' as Commitment,
    disableRetryOnRateLimit: false
  };
  return new Connection(FREE_SOLANA_RPC, connectionConfig);
}

export async function signAndSendTransaction(
  tx: SolanaTransaction,
  solana: PhantomSolanaProvider
): Promise<string> {
  const connection = getSolanaConnection();
  let versionedTransaction: VersionedTransaction | undefined = undefined;
  let transaction: Transaction | undefined = undefined;
  if (tx.serializedMessage != null) {
    if (tx.txType === 'VERSIONED') {
      versionedTransaction = VersionedTransaction.deserialize(new Uint8Array(tx.serializedMessage));
      const blockhash = (await connection.getLatestBlockhash('confirmed')).blockhash;
      if (!!blockhash) versionedTransaction.message.recentBlockhash = blockhash;
    } else if (tx.txType === 'LEGACY') {
      transaction = Transaction.from(Buffer.from(new Uint8Array(tx.serializedMessage)));
      transaction.feePayer = new PublicKey(tx.from);
      transaction.recentBlockhash = undefined;
    }
  } else {
    transaction = new Transaction();
    transaction.feePayer = new PublicKey(tx.from);
    if (tx.recentBlockhash) transaction.recentBlockhash = tx.recentBlockhash;
    else transaction.recentBlockhash = (await connection.getLatestBlockhash('confirmed')).blockhash;
    tx.instructions.forEach((instruction) => {
      transaction?.add(
        new TransactionInstruction({
          keys: instruction.keys.map((accountMeta) => ({
            pubkey: new PublicKey(accountMeta.pubkey),
            isSigner: accountMeta.isSigner,
            isWritable: accountMeta.isWritable
          })),
          programId: new PublicKey(instruction.programId),
          data: Buffer.from(instruction.data)
        })
      );
    });
    tx.signatures.forEach(function (signatureItem) {
      const signature = Buffer.from(new Uint8Array(signatureItem.signature));
      const publicKey = new PublicKey(signatureItem.publicKey);
      transaction?.addSignature(publicKey, signature);
    });
  }
  const finalTx: Transaction | VersionedTransaction = transaction || versionedTransaction;
  if (!finalTx) throw new Error('Error creating transaction');
  console.log({ finalTx });
  const { signature } = await solana.signAndSendTransaction(finalTx, {
    preflightCommitment: 'confirmed'
  });
  const status = await connection.getSignatureStatus(signature);
  console.log({ status });
  return signature;
}
