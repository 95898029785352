export const META_SAMPLES = [
  {
    label: '1. Get Meta',
    value: 'get-meta'
  },
  {
    label: '2. Get Chains',
    value: 'get-chains'
  }
];

const GET_META_CODE = `
(async () => {
    const { blockchains, tokens, swappers } = await rango.meta()
    console.log({ blockchains, swappers })
})()
`;

const GET_BLOCKCHAINS_CODE = `
(async () => {
    const chains = await rango.chains()

    // filter active evm chains
    const evm = chains.filter(chain => chain.type === "EVM" && chain.enabled)
    console.log(\`found \${evm.length} evm chains\`)

    // find chain by id
    const cosmos = chains.find(chain => chain.chainId === 'cosmoshub-4')
    console.log({ cosmos })
})()
`;

export const META_SAMPLES_CODES = {
  'get-meta': GET_META_CODE.trimStart(),
  'get-chains': GET_BLOCKCHAINS_CODE.trimStart()
};
