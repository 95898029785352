export const SWAP_SAMPLES = [
  {
    label: '1. EVM: BSC Sample DEX',
    value: 'bsc-dex'
  },
  {
    label: '2. EVM: BSC to Polygon Bridge',
    value: 'bsc-to-polygon'
  }
];

const BSC_DEX_CODE = `
(async () => {
  const tokens = (await rango.meta()).tokens
  const usdt_address = "0x55d398326f99059ff775485246999027b3197955"
  const bnb = tokens.find(t => t.blockchain == "BSC" && t.address == null)
  const usdt = tokens.find(t => t.blockchain == "BSC" && t.address == usdt_address)
  const amount = "100000000000000000"
  const accounts = await ethereum.request({ method: 'eth_requestAccounts' })
  const address = accounts?.[0]

  const swap = await rango.swap({ 
    from: bnb, to: usdt, amount, slippage: "3", fromAddress: address, toAddress: address
  })
  console.log(swap)  
})()
`;

const BSC_BRIDGE_CODE = `
(async () => {
  const tokens = (await rango.meta()).tokens
  const bnb = tokens.find(t => t.blockchain == "BSC" && t.address == null)
  const matic = tokens.find(t => t.blockchain == "POLYGON" && t.address == null)
  const amount = "100000000000000000"
  const accounts = await ethereum.request({ method: 'eth_requestAccounts' })
  const address = accounts?.[0]

  const swap = await rango.swap({ 
    from: bnb, to: matic, amount, slippage: "3", fromAddress: address, toAddress: address
  })
  console.log(swap) 
})()
`;

export const SWAP_SAMPLES_CODES = {
  'bsc-dex': BSC_DEX_CODE.trimStart(),
  'bsc-to-polygon': BSC_BRIDGE_CODE.trimStart()
};
