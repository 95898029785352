export const SIGNER_SAMPLES = [
  {
    label: '1. Sign a swap on Polygon (sdk)',
    value: 'evm-signer-sdk'
  },
  {
    label: '2. Sign a swap on Polygon (detailed)',
    value: 'evm-signer-ether'
  },
  {
    label: '3. Sign a swap on Solana',
    value: 'solana-signer'
  }
];

const EVM_SIGNER_CODE = `
(async () => {
  const tokens = (await rango.meta()).tokens
  const usdt_address = "0xc2132d05d31c914a87c6611c10748aeb04b58e8f"
  const matic = tokens.find(t => t.blockchain == "POLYGON" && t.address == null)
  const usdt = tokens.find(t => t.blockchain == "POLYGON" && t.address == usdt_address)
  const amount = "100000000000000000"
  const accounts = await ethereum.request({ method: 'eth_requestAccounts' })
  const address = accounts?.[0]
  const swap = await rango.swap({ 
      from: matic, to: usdt, amount, slippage: "3", fromAddress: address, toAddress: address
  })

  // if tx created successfuly, proceed to sign step
  if (swap.resultType === "OK" && swap.tx) {
    const chainId = toHex(swap?.tx?.blockChain?.chainId);
    await switchNetwork(chainId)
    await signTransaction(swap)  
  } else {
    console.log(swap.error)
  }
})()

async function signTransaction(swap) {
  const tx = buildTx(swap.tx)
  console.log({ tx })
  const txHash = await ethereum.request({
      method: 'eth_sendTransaction',
      params: [tx],
  })
  console.log({txHash})
}

function buildTx(evmTx, isApprove = false) {
  let tx = {};
  if (evmTx.from) tx = { ...tx, from: evmTx.from }
  if (isApprove) {
    if (evmTx.approveTo) tx = { ...tx, to: evmTx.approveTo }
    if (evmTx.approveData) tx = { ...tx, data: evmTx.approveData }
  } else {
    if (evmTx.txTo) tx = { ...tx, to: evmTx.txTo }
    if (evmTx.txData) tx = { ...tx, data: evmTx.txData }
  }
  if (evmTx.value) tx = { ...tx, value: evmTx.value }
  if (evmTx.nonce) tx = { ...tx, nonce: evmTx.nonce }
  if (evmTx.gasLimit) tx = { ...tx, gasLimit: evmTx.gasLimit }
  if (evmTx.gasPrice)  tx = { ...tx, gasPrice: toHex(evmTx.gasPrice) }
  // if (evmTx.maxFeePerGas) 
  //   tx = { ...tx, maxFeePerGas: toHex(evmTx.maxFeePerGas) }
  // if (evmTx.maxPriorityFeePerGas) 
  //   tx = { ...tx, maxPriorityFeePerGas: toHex(evmTx.maxPriorityFeePerGas) }
  return tx;
}

async function switchNetwork(chainId) {
  await ethereum.request({
    method: 'wallet_switchEthereumChain',
    params: [{ chainId }], 
  });
}

function toHex(d) {
  return  "0x" + ("0"+(Number(d).toString(16))).slice(-2).toUpperCase()
}
`;

const EVM_SIGNER_SDK_CODE = `
(async () => {
  const tokens = (await rango.meta()).tokens
  const usdt_address = "0xc2132d05d31c914a87c6611c10748aeb04b58e8f"
  const matic = tokens.find(t => t.blockchain == "POLYGON" && t.address == null)
  const usdt = tokens.find(t => t.blockchain == "POLYGON" && t.address == usdt_address)
  const amount = "100000000000000000"
  const accounts = await ethereum.request({ method: 'eth_requestAccounts' })
  const address = accounts?.[0]
  const swap = await rango.swap({ 
      from: matic, to: usdt, amount, slippage: "3", fromAddress: address, toAddress: address
  })

  const chainId = toHex(swap?.tx?.blockChain?.chainId);
  await switchNetwork(chainId)

  // signer = new ethers.providers.Web3Provider(ethereum).getSigner()
  await rango.executeEvmRoute(signer, swap)
})()

async function switchNetwork(chainId) {
  await ethereum.request({
    method: 'wallet_switchEthereumChain',
    params: [{ chainId }], 
  });
}

function toHex(d) {
  return  "0x" + ("0"+(Number(d).toString(16))).slice(-2).toUpperCase()
}
`;

const SOLANA_SIGNER_CODE = `
(async () => {
  const tokens = (await rango.meta()).tokens
  const usdt_address = "Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB"
  const sol = tokens.find(t => t.blockchain == "SOLANA" && t.address == null)
  const usdt = tokens.find(t => t.blockchain == "SOLANA" && t.address == usdt_address)
  const amount = "1000"
  if (!solana) throw new Error("Please connect your solana wallet first")
  const address = solana.publicKey.toString()
  const swap = await rango.swap({ 
      from: sol, to: usdt, amount, slippage: "3", fromAddress: address, toAddress: address
  })
  if (swap.resultType === "OK" && swap.tx) {
    // reference to implementation
    const hash = await signAndSendSolanaTransaction(swap.tx, solana) 
    console.log(hash)
  } else {
    console.log(swap.error)
  }
})()
`;

export const SIGNER_SAMPLES_CODES = {
  'evm-signer-sdk': EVM_SIGNER_SDK_CODE.trimStart(),
  'evm-signer-ether': EVM_SIGNER_CODE.trimStart(),
  'solana-signer': SOLANA_SIGNER_CODE.trimStart()
};
