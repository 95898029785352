import {
  BALANCE_SAMPLES,
  BALANCE_SAMPLES_CODES,
  META_SAMPLES,
  META_SAMPLES_CODES,
  QUOTE_SAMPLES,
  QUOTE_SAMPLES_CODES,
  SIGNER_SAMPLES,
  SIGNER_SAMPLES_CODES,
  STATUS_SAMPLES,
  STATUS_SAMPLES_CODES,
  SWAP_SAMPLES,
  SWAP_SAMPLES_CODES
} from '../../data/samples';
import { MdOutlineSwapCalls } from 'react-icons/md';
import { IoSwapHorizontal } from 'react-icons/io5';
import { FaFileSignature } from 'react-icons/fa6';
import { MdOutlineContactSupport } from 'react-icons/md';
import { MdAccountBalance } from 'react-icons/md';
import { TbStatusChange } from 'react-icons/tb';

export const MenuItems = [
  {
    label: 'Quote',
    value: 'quote',
    icon: MdOutlineSwapCalls
  },
  {
    label: 'Swap',
    value: 'swap',
    icon: IoSwapHorizontal
  },
  {
    label: 'Status',
    value: 'status',
    icon: TbStatusChange
  },
  {
    label: 'Sign',
    value: 'sign',
    icon: FaFileSignature
  },
  {
    label: 'Meta',
    value: 'meta',
    icon: MdOutlineContactSupport
  },
  {
    label: 'Balance',
    value: 'balance',
    icon: MdAccountBalance
  }
];

export const MenuSampleCodes = {
  meta: META_SAMPLES_CODES,
  quote: QUOTE_SAMPLES_CODES,
  swap: SWAP_SAMPLES_CODES,
  sign: SIGNER_SAMPLES_CODES,
  balance: BALANCE_SAMPLES_CODES,
  status: STATUS_SAMPLES_CODES
};

export const MenuSampleOptions = {
  meta: META_SAMPLES,
  quote: QUOTE_SAMPLES,
  swap: SWAP_SAMPLES,
  sign: SIGNER_SAMPLES,
  balance: BALANCE_SAMPLES,
  status: STATUS_SAMPLES
};
