export const STATUS_SAMPLES = [
  {
    label: '1. Check swap status',
    value: 'tx-status'
  }
];

const CHECK_STATUS_CODE = `
(async () => {
  const tokens = (await rango.meta()).tokens
  const usdt_address = "0x55d398326f99059ff775485246999027b3197955"
  const bsc_bnb = tokens.find(t => t.blockchain == "BSC" && t.address == null)
  const bsc_usdt = tokens.find(t => t.blockchain == "BSC" && t.address == usdt_address)
  const amount = "100000000000000000"
  const accounts = await ethereum.request({ method: 'eth_requestAccounts' })
  const address = accounts?.[0]

  const swap = await rango.swap({ 
    from: bsc_bnb, to: bsc_usdt, amount, slippage: "3", fromAddress: address, toAddress: address
  })
  // sample tx hash
  const txHash = '0xfa88b705a5b4049adac7caff50c887d9600ef023ef1a937f8f8b6f44e90042b5' 
  const status = await rango.status({ requestId: swap.requestId, txId: txHash })
  console.log(status);
})()`;

export const STATUS_SAMPLES_CODES = {
  'tx-status': CHECK_STATUS_CODE.trimStart()
};
